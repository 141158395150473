.post-header {
    margin-bottom: $vertical-spacing-unit;
}
.post-list {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        border-bottom: 1px solid $light-gray;
        padding-bottom: $space-3;
        padding-top: $space-3;
    }
}
.post-pretitle {
    // font-size: 32px;
    color: $grey-color-bitlight;
}
.post-title {
    font-size: 42px;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 0;
    // @include media-query($on-laptop) {
    //     font-size: 36px;
    // }
}
.post-content {
    h2 {
        font-size: 42px;
        // @include media-query($on-laptop) {
        //     font-size: 28px;
        // }
    }
    h3 {
        font-size: 30px;
        // @include media-query($on-laptop) {
        //     font-size: 22px;
        // }
    }
    h4 {
        font-size: 20px;
        // @include media-query($on-laptop) {
        //     font-size: 18px;
        // }
    }
}
.post-meta {
    color: $grey-color;
    font-size: $small-font-size;
    margin-bottom: 0px;
}
.post-link {
    display: block;
    font-size: 42px;
}

.post-description {
    font-style: italic;
}

.post-description-home {
    font-weight: 900;
}
