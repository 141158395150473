a {
    color: $text-color;
    text-decoration: none;

    &:hover {
        color: $theme-color;
        text-decoration: none;
    }
}

article a, .news a, .calendar a {
    color: $theme-color;
    font-weight: 100;
    &:hover {
        text-decoration: underline;
    }
}

.social a {
    color: $text-color;
    &:hover { color: $theme-color; }
}


// a {
//   color: $link-color;
//   background-image: linear-gradient(to top,
//     rgba(0,0,0,0) 13%,
//     rgba($link-color,.8) 13%,
//     rgba($link-color,.8) 18%,
//     rgba(0,0,0,0) 17%
//   );
//   text-shadow: white 1px 0px 0px, white -1px 0px 0px;
// }
//
// a:hover,
// a:focus,
// a:active {
//   border: 0;
//   color: $link-hover-color;
//   text-decoration: none;
//   background-image: linear-gradient(to top,
//     rgba(0,0,0,0) 13%,
//     rgba($link-hover-color,.8) 13%,
//     rgba($link-hover-color,.8) 17%,
//     rgba(0,0,0,0) 17%
//   );
//   text-shadow: white 1px 0px 0px, white -1px 0px 0px;
// }
//
// // Correct issues with buttons
// button,
// .button {
//   text-shadow: none;
//   background-image: none;
// }
//
// .button:hover,
// .button:focus,
// .button:active {
//   color: white;
//   text-shadow: none;
//   background-image: none;
// }
//
// .anchorjs-link {
//     text-shadow: none;
//     background-image: none;
// }
// .anchorjs-link:hover,
// .anchorjs-link:focus,
// .anchorjs-link:active{
//     border: 0;
//     color: $link-hover-color;
//     text-shadow: none;
//     background-image: none;
// }
