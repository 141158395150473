pre,
pre code {
  background-color: transparent;
  border: 2px solid $grey-color-light;
  border-radius: $pre-border-radius;
  font-family: $code-font-family;
}


code {
  // color: $code-color;
  font-size: 85%;
  padding-bottom: 0.1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-top: 0.1em;
  background: $code-background-color;
    
}


pre {
    padding: 6px 6px;
    overflow-x: auto;
    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

// .highlight .p {
//   font-size: 1.125rem;
//   line-height: 1;
// }

pre {
    counter-reset: line-numbering;
    white-space: pre;
    overflow-x: auto;
    word-break: inherit;
    word-wrap: inherit;
}

pre a {
    background-image: none;
}

pre a::before {
  content: counter(line-numbering);
  counter-increment: line-numbering;
  padding-right: 1em; /* space after numbers */
  width: 25px;
  text-align: right;
  opacity: 0.7;
  display: inline-block;
  color: $light-gray;
  margin-right: 16px;
  font-size: 13px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

pre a:first-of-type::before {
  padding-top: 10px;
}

pre a:last-of-type::before {
  padding-bottom: 10px;
}

pre a:only-of-type::before {
  padding: 10px;
}
