/* Basscss Color Base */

body {
  color: $dark-gray;
  background-color: white;
}

a {
  color: $theme-color;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

// pre,
// code {
//   background-color: $lighter-gray;
//   border-radius: $border-radius;
// }

hr {
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: $border-width;
  border-bottom-color: $border-color;
}
