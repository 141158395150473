.profile {

    img {
        // box-shadow: 0 0 5px $grey-color;
        width: 100%;
    }
}

.card {
    background-color: $mcqll-theme-color;
    box-shadow: 0 0 2px $grey-color-light;
    position: relative;
    margin-right: 3vw;
    margin-bottom: 1vw;

    @media screen and (max-width: $viewport-small) {

    }

    .link {
        font-weight: bold;
        p { margin: 0; }

        // a {
        //     font-weight: bold;
        // }
    }
}

.logo {
    img {
        width: 100%;
    }
}

.address {
    font-family:serif;
    color: black;
    p { margin: 0; }
}



.news {
    border-top: 1px solid $grey-color-light;
    margin-top: 30px;
    padding-top: 20px;

    h2 {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 10px;
    }
    table {
        border-collapse: collapse;
        width: 100%;

        .date { width: 19%; }
        .announcement { width: 81%; }

        tr { text-align: left; }
        td {
            padding: 6px 0;
            vertical-align: top;
            border: none;
        }
    }
}

.social, .calendar {
    border-top: 1px solid $grey-color-light;
    margin-top: 50px;
    padding-top: 20px;
}

