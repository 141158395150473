.meetings {
    table {
        border-collapse: collapse;
        width: 100%;
    }
    .date {
        width: 30%;
    }
    .announcement {
        width: 81%;
    }
    tr {
        text-align: left;
    }
    td {
        vertical-align: top;
        border-top: 1px solid $grey-color-light !important;
    }

}

.toggle-table {
    display: none;
    margin-bottom: 2em;
}


.toggle-table.open {
    display: block;
}


h3.year-toggle {
    cursor: pointer;
    user-select: none;
}

h3.year-toggle::before {
    content: "▸ ";
    transition: transform 0.2s ease;
}

h3.year-toggle.open::before {
    content: "▾ ";
}