.personlist {
    display: inline;
    list-style: none;
    padding-inline-start: unset;
}
  
.personlist li {
    display: inline;
}

.personlist li:after {
    content: ", ";
}

.personlist li:last-child:after {
    content: ".";
}

.alumlist {
    display: block;
    list-style-type: disc;
    padding-left: 20px;
}

.alumlist li {
    display: list-item;
}

.alumlist li:after {
    content: unset; 
}

.person {
    box-sizing: border-box;
    float: left;
    height: 250px;
    padding: 10px;
    vertical-align: middle;
    width: 33.33%;

    .thumbnail {
        overflow: hidden;
        height: 220px;
        width: 100%;

        img {
            margin-left: 50%;
            transform: translateX(-50%);
            // position: absolute;
            //     right: -9999px;
            //     left: -9999px;
            //     top: -9999px;
            //     bottom: -9999px;
            //     margin: auto;
            height: auto;
            width: 215px;
        }

        a {
            float: left;
            height: 220px; // this will get the image to centre a bit higher. more likely good for portraits
            position: relative;
            width: 100%;

            span {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0,0,0,0.4);
                color: $grey-color-light;
                padding: 40px;
                text-align: center;

                h1 {
                    font-size: 1.5rem;
                    margin: 0;
                }

                p {
                    color: $grey-color-light;
                }
            }

            &:hover {
                span {
                    display: block;
                }
            }
        }
    }
}
