div #gridid {
    min-width: 520px;
    .row {
        background-color: $mcqll-theme-color;
        width: 100%;
        height: 320px;
        position: inherit;
        border: 1px $grey-color-light;
    }
}
